@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    /* Not used */
    /*
    @font-face {
        font-family: 'Univia Pro';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src:
            url(../fonts/univiapro-bold.woff2) format('woff2'),
            url(../fonts/univiapro-bold.woff) format('woff');
    }
    */

    @font-face {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src:
            url(../fonts/proxima-nova_regular.woff2) format("woff2"),
            url(../fonts/proxima-nova_regular.woff) format("woff");
    }

    @font-face {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src:
            url(../fonts/proxima-nova_bold.woff2) format("woff2"),
            url(../fonts/proxima-nova_bold.woff) format("woff");
    }

    @font-face {
        font-family: "Fira Code";
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src:
            url(../fonts/firacode-regular.woff2) format("woff2"),
            url(../fonts/firacode-regular.woff) format("woff");
    }

    @font-face {
        font-family: "Proxima Nova Fallback";
        size-adjust: 99%;
        ascent-override: 88%;
        src: local("Arial");
    }

    /* Use only in the wysiwyg editor */
    /*
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        @apply bg-profinit-red font-bold;
    }

    h1,
    .h1 {
        @apply mb-12 mt-[30px] font-univia text-[3rem];
    }
    h2,
    .h2 {
        @apply mb-12 mt-[30px] font-univia text-[2.5rem];
    }
    h3,
    .h3 {
        @apply text-[2.25rem];
    }
    h4,
    .h4 {
        @apply text-[2rem];
    }
    h5,
    .h5 {
        @apply text-4xl md:text-5xl;
    }
    h6,
    .h6 {
        @apply text-2xl;
    }
    */

    .embla {
        --gap: 0.625rem;
        --slides: 1;
        --slideWidthBySlides: calc((100% - (var(--slides) - 1) * var(--gap)) / var(--slides));
        --slideWidthMax: calc(16777214px);
        --slideWidth: min(var(--slideWidthMax), var(--slideWidthBySlides));
        position: relative;
    }
    .embla_viewport {
        overflow: hidden;
    }
    .embla_container {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: var(--slideWidth);
        -moz-column-gap: var(--gap);
        column-gap: var(--gap);
    }
    .embla.isDisabled .embla_container,
    .embla.isDisabled .embla_viewport {
        display: contents;
    }
    .embla.isDisabled .embla_dots,
    .embla.isDisabled .embla_next,
    .embla.isDisabled .embla_prev,
    .embla.isSingleSlide .embla_dots,
    .embla.isSingleSlide .embla_next,
    .embla.isSingleSlide .embla_prev {
        display: none;
    }

    .aomask {
        mask-image: url(../images/m.svg), url(../images/m.svg), url(../images/m-corner.svg);
        mask-repeat: repeat-y, repeat-x, no-repeat;
        mask-position:
            right,
            top,
            right center;
        mask-size:
            24px,
            auto 50%,
            36px 24px;
    }

    .tab-nav-item {
        @apply bg-black/5;

        &.active {
            @apply bg-white;
        }

        &:not(.active) {
            @apply shadow-tabs;
        }
    }

    .tab-item {
        @apply invisible h-0 p-0 opacity-0;

        &.active {
            @apply visible h-[calc(100%-54px)] p-[38px] opacity-100;
        }
    }

    .navigation-section {
        @apply max-md:z-30 max-md:bg-white max-md:transition-transform max-md:duration-250 max-md:motion-reduce:transform-none;

        .main-navigation {
            @apply max-md:fixed max-md:h-[100dvh] max-md:w-screen max-md:translate-x-0 max-md:overflow-y-scroll max-md:bg-white max-md:transition-transform max-md:duration-250 max-md:motion-reduce:transform-none;

            &.active {
                @apply max-md:-translate-x-full;
            }
        }

        &.active {
            @apply max-md:fixed max-md:top-0 max-md:z-30;
        }

        &.navigation-section--primary {
            @apply max-md:fixed max-md:left-0 max-md:top-0;

            .main-navigation {
                @apply max-md:top-[70px];
            }

            &:not(.navigation-section__fixed) {
                @apply max-md:translate-y-[-70px];
            }

            &.active {
                @apply max-md:bg-white;
            }

            &.navigation-section__fixed {
                @apply max-md:translate-y-0;
            }
        }

        &.navigation-section--secondary {
            @apply max-md:fixed;

            &.active {
                @apply max-md:bg-darkblue-100;
            }
        }
    }

    .navigation-controls {
        &.navigation-controls--primary {
            @apply text-gray-80;
        }

        &.navigation-controls--secondary {
            @apply rounded-full;

            @apply text-white hover:bg-white/15;

            &.active {
                @apply bg-white text-darkblue-100;
            }
        }
    }

    .menu-column {
        @apply max-md:fixed max-md:left-0 max-md:top-0 max-md:z-30 max-md:bg-white max-md:transition-transform max-md:duration-250 max-md:motion-reduce:transform-none;

        .sub-navigation {
            @apply max-md:fixed max-md:top-[60px] max-md:h-[100dvh] max-md:w-screen max-md:translate-x-0 max-md:overflow-y-scroll max-md:bg-white max-md:transition-transform max-md:duration-250 max-md:motion-reduce:transform-none;

            &.active {
                @apply max-md:-translate-x-full;
            }

            .sub-navigation-item {
                &.active {
                    @apply bg-darkblue-10 text-darkblue-100;

                    svg {
                        @apply text-profinit-red;
                    }
                }
            }
        }

        &.active {
            @apply max-md:fixed max-md:top-0 max-md:z-30;
        }

        &.menu-column--primary {
            &.active {
                @apply max-md:bg-white;
            }
        }

        &.menu-column__fixed {
            @apply max-md:translate-y-0;
        }

        &:not(.menu-column__fixed) {
            @apply max-md:translate-y-[-60px];
        }
    }

    .navigation-icon {
        @apply cursor-pointer;

        &:not(.active) {
            @apply text-white hover:bg-white/15;
        }

        &.active {
            @apply bg-white text-darkblue-100;
        }
    }

    .details-toggle-secondary {
        @apply xl:mt-0 xl:pt-0;

        &.active {
            @apply max-xl:mt-5 max-xl:pt-7;
        }
        &:not(.active) {
            @apply max-xl:after:hidden;
        }
    }

    .custom-select {
        &.custom-select--reverted {
            .select-dropdown {
                @apply bottom-[47px] md:bottom-[39px];
            }

            &.active {
                .select-button {
                    @apply bg-gradient-dropdown-reverse rounded-b-md;
                }

                .select-dropdown {
                    @apply rounded-t-md;
                }
            }
        }

        &:not(.custom-select--reverted) {
            &.active {
                .select-button {
                    @apply bg-gradient-dropdown rounded-t-md;
                }

                .select-dropdown {
                    @apply border-t-0 rounded-b-md;
                }
            }
        }

        &.active {
            .arrow {
                @apply rotate-180;
            }

            .select-dropdown {
                @apply opacity-100 visible scale-y-100;
            }

            &.select-button--active {
                .select-button {
                    @apply border-blue-40;
                }
            }

            &:not(.select-button--active) {
                .select-button {
                    @apply border-darkblue-60;
                }
            }
        }

        &:not(.active) {
            &.select-button--active {
                .select-button {
                    @apply border-blue-40;
                }
            }

            &:not(.select-button--active) {
                .select-button {
                    @apply bg-darkblue-10 border-darkblue-10;
                }
            }

            .select-button {
                @apply rounded-md hover:border-darkblue-40;
            }
        }
    }

    .select-dropdown-item-custom-list {
        &.active {
            @apply block;
        }

        &:not(.active) {
            @apply hidden;
        }
    }

    .select-dropdown-item-custom-button {
        &.active {
            @apply max-lg:hidden lg:block;
        }

        &:not(.active) {
            @apply hidden;
        }
    }

    .icon-filter {
        @apply fill-white stroke-darkblue-60 hover:stroke-profinit-gray;

        .icon-filter--normal {
            @apply block;
        }

        .icon-filter--active {
            @apply hidden;
        }
        &.active {
            @apply stroke-darkblue-100;

            .icon-filter--normal {
                @apply hidden;
            }

            .icon-filter--active {
                @apply block;
            }
        }
    }

    .notification-item {
        p {
            a {
                @apply text-profinit-red underline hover:no-underline;
            }
        }
    }

    .notification-modal {
        &.active {
            @apply block;
        }

        &:not(.active) {
            @apply hidden;
        }
    }

    .filter-search-delete {
        &.active {
            @apply block;
        }

        &:not(.active) {
            @apply hidden;
        }
    }
}

@layer utilities {
    .scale-x-flip {
        transform: scaleX(-1);
    }
}

html {
    scroll-behavior: smooth;
}

.playground {
    min-height: 100vh;
}

.language-json > .react-syntax-highlighter-line-number {
    min-width: 3.25em !important;
}

.python-lib-code {
    background-color: rgb(236 238 245) !important;
    > .language-python {
        > span:first-child {
            display: none;
        }
        > span:last-child {
            display: none;
        }
    }
    code span {
        background: none !important;
    }
}

.dots-container {
    position: relative;
}

.dots {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100px;
}

.embla__dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
}

.embla__dot {
    -webkit-tap-highlight-color: rgba(49, 49, 49, 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin-left: 12px;
    width: 1.1rem;
    height: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.embla__dot:after {
    box-shadow: inset 0 0 0 0.3rem rgb(125, 125, 125);
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: "";
}

.embla__dot--selected:after {
    box-shadow: inset 0 0 0 0.3rem rgb(234, 234, 234);
}

.policy-list {
    list-style: disc;
    padding-inline-start: 40px;
}

#cc-main {
    --cc-btn-primary-bg: rgb(43 67 108);
    --cc-btn-primary-hover-bg: rgb(18 42 83);
}

.navbar-spacer {
    height: 60px;
    visibility: hidden;
}

.copy-input-container {
    position: relative;
}

.token-input {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    outline: none;
    &:focus {
        outline: none;
        box-shadow: none;
        border-color: #ccc;
    }
}

.copy-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    padding-left: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 18px;
    color: #465a9b;
    background-color: #f9f9f9;
}

.copy-icon:hover {
    color: #122a53;
    cursor: pointer;
}

.copy-icon:active {
    color: #003f7f;
}

.tick-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    padding-left: 10px;
    transform: translateY(-50%);
    color: #465a9b;
    background-color: #f9f9f9;
}

.score-list {
    list-style-type: decimal;
    padding: 30px;
}
